import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const ComingSoon = ({ data, location }) => {
  return (
    <Layout headerType="solid" location={location}>
      <GatsbySeo noindex={true} />
      <SeoComponent
        title="Custom Woodworking Shops Near Me - Thank You"
        description="For high quality handmade wood furniture, Eden Oaks provides custom woodworking in Colorado Springs and surrounding areas. Call us to discuss your project!"
      />
      <div className="solid-header-page-spacer" />
      <div className="pb-10">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <h1 className="mb-4">COMING SOON!</h1>
              <h3 className="mb-3">Woodworking Classes at Eden Oaks</h3>
              <p className="mb-3">
                Eden Oaks is excited to announce upcoming woodworking classes
                designed for enthusiasts of all skill levels. Participants will
                learn fundamental techniques, gain hands-on experience with
                various tools, and create beautiful, handcrafted projects.
              </p>
              <p className="mb-3">
                Whether you're a beginner or an experienced woodworker, our
                expert instructors will guide you through each step, ensuring a
                rewarding and educational experience. Stay tuned for more
                details and registration information!
              </p>
              {/* <p>
                If your inquiry is urgent you may call us at{" "}
                <a href="tel:719-985-3336">(719) 985-3336</a>.
              </p> */}
              <Link
                to="/"
                className="mt-4 btn btn-primary text-white return-to-home-btn"
              >
                Return Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ComingSoon
